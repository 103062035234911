








































































































import Vue from 'vue'
import { tourDataService, TourStatus } from '../services/tour-data-service'
import { ConfirmationData } from '@/types/confirmation-data'
import PoweredByButterfly from './PoweredByButterfly.vue'
import { AxiosError } from 'axios'
import { ServerError } from '@/types/errors'
import { TourResponse } from '@/types/tour-request-response'
import AddToCalendarGoogle from './confirmation/AddToCalendarGoogle.vue'
import AddToCalendarIcs from './confirmation/AddToCalendarIcs.vue'
import EntryInstructionPanel from './confirmation/EntryInstructionPanel.vue'
import ErrorDialog from './ErrorDialog.vue'
import { TourUnit, AllKeys } from '@/types/building-info'
import { dateTimeService, ReservationField } from '@/services/datetime-service'
import { GoogleTagEvents, logBuilding } from '@/services/events'

export default Vue.extend({
  name: 'Confirmation',
  components: {
    PoweredByButterfly,
    ErrorDialog,
    AddToCalendarGoogle,
    AddToCalendarIcs,
    EntryInstructionPanel
  },
  data (): ConfirmationData {
    return {
      building: null,
      reservation: null,
      error: false,
      loading: true,
      loaded: false,
      saveError: false,
      saveErrorText: '',
      schedulesEnabled: false,
      fullStyle: 'sgt-card__full'
    }
  },

  computed: {
    appointment (): string {
      return dateTimeService.formatReservationTime(this.reservation, this.building)
    },
    appointmentStart (): string {
      return dateTimeService.formatReservationTime(this.reservation, this.building, ReservationField.STARTS_AT, 't')
    },
    appointmentEnd (): string {
      return dateTimeService.formatReservationTime(this.reservation, this.building, ReservationField.ENDS_AT, 't')
    },
    sharedKeyUnits (): string {
      if (this.reservation?.attributes.tour_units != null) {
        return this.reservation.attributes.tour_units
          .filter((value: TourUnit) => value.shared_key_unit)
          .map(value => value.label).join(', ')
      } else {
        return ''
      }
    },
    allKeys (): AllKeys | undefined {
      return tourDataService.getAllKeys(this.reservation)
    },
    units (): string | undefined {
      if (this.reservation) {
        return this.reservation.attributes.tour_units.map((value: TourUnit) => value.label).join(', ')
      } else {
        return ''
      }
    }
  },

  methods: {
    reschedule () {
      this.$gtag.event(GoogleTagEvents.Reschedule)
      this.$router.push({ name: 'schedule', params: { handle: this.building?.attributes.handle as string } })
    },
    async cancelTour () {
      try {
        const tourId: string = this.$route.params.id
        const tourResponse: TourResponse = await tourDataService.cancelTour(`${tourId}`)
        // Save the updated, cancelled tour, in case they try to reschedule it.
        this.$store.commit('saveReservation', tourResponse)
        this.$router.replace({ name: 'tourcanceled', params: { id: tourId } })
      } catch (e) {
        this.saveError = true
        if (e.isAxiosError) {
          const axiosError: AxiosError = e as AxiosError
          const serverError: ServerError = axiosError.response?.data
          if (serverError?.errors?.length > 0) {
            this.saveErrorText = e.message + ': ' + serverError.errors[0].detail
          } else {
            this.saveErrorText = e.message + ' (no detail)'
          }
        } else {
          this.saveErrorText = e.message
        }
      } finally {
        this.loading = false
      }
    }
  },

  async mounted () {
    console.log('Loading')
    const tourId: string = this.$route.params.id
    try {
      const tourResponse = await tourDataService.getTourInfo(`${tourId}`)
      logBuilding(this.$gtag, tourResponse.attributes.building_handle)
      this.$store.commit('saveReservation', tourResponse)
      console.log('Loaded, info=', tourResponse)
      this.reservation = tourResponse
      if (!this.$store.state.buildingInfo?.attributes?.name) {
        const buildingResponse = await tourDataService.getBuildingInfo(tourResponse.attributes.building_handle)

        this.building = buildingResponse
        // Update the app bar
        this.$store.commit('saveBuildingInfo', this.building)
      } else {
        this.building = this.$store.state.buildingInfo
      }

      if (tourResponse.attributes.status === TourStatus.cancelled) {
        console.log('Tour canceled, going to cancel page')
        this.$router.push({ name: 'tourcanceled', params: { id: tourId } })
      } else {
        this.schedulesEnabled = this.building?.attributes.enable_scheduling_portal ?? false
        this.loading = false
        this.loaded = true
      }
    } catch (reason) {
      console.warn('Error retrieving api: ', reason)
      this.error = true
      this.loading = false
      this.$router.push({ name: 'tourohfour', params: { id: tourId } })
    }
  }
})
