import { BuildingInfo } from '@/types/building-info'
import { TourResponse } from '@/types/tour-request-response'
import { DateTime } from 'luxon'

export enum ReservationField {
  STARTS_AT = 'starts_at',
  ENDS_AT = 'ends_at'
}
export default class DateTimeService {
  formatReservationTime (reservation: TourResponse|null, building: BuildingInfo|null, field = ReservationField.STARTS_AT, format = 'EEEE, MMM d @ t'): string {
    console.log('DateTimeService: Local time zone is name=', DateTime.local().zoneName, ', offset=', DateTime.local().offset, ', longName=', DateTime.local().offsetNameLong)
    console.log('DateTimeService: Local time is ....... ', DateTime.local().toISO())
    if (!reservation) {
      console.warn('DateTimeService: No reservation')
      return '[No reservation]'
    } else if (!building) {
      console.warn('DateTimeService: No building')
      return '[No building]'
    } else if (reservation.attributes[field] && building.attributes.time_zone) {
      console.log('DateTimeService: Reservation is for... ', reservation.attributes[field])
      console.log('DateTimeService: Building time zone is ', building.attributes.time_zone)
      const isoTime = DateTime.fromISO(reservation.attributes[field], { setZone: true })
      console.log('DateTimeService: Reservation ISO time .', isoTime.toISO())
      const buildingTime = isoTime.setZone(building.attributes.time_zone)
      console.log('DateTimeService: Building time is .... ', buildingTime.toISO())
      const displayTime = buildingTime.toFormat(format)
      console.log('DateTimeService: Display time is ..... ', displayTime)
      return displayTime
    } else if (reservation.attributes.starts_at && !building.attributes.time_zone) {
      console.warn('DateTimeService: No Building Time Zone')
      return '[Unknown Building Time Zone]'
    } else if (!reservation.attributes[field]) {
      console.warn('DateTimeService: No reservation')
      return '[No reserved time]'
    } else {
      console.warn('DateTimeService: Unknown format error')
      return '[Unknown error]'
    }
  }
}

// A singleton instance
export const dateTimeService = new DateTimeService()
