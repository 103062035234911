import { DateTime, Zone } from 'luxon'
import { VSelectItemType } from './v-select-item-type'

export interface TimeSlot {
    display: string;
    date: DateTime;
}
export class Availability {
    private dates: Array<VSelectItemType> = [];

    private times: Map<string, Array<TimeSlot>> = new Map();
    private zone: Zone

    selectedDate: DateTime|null = null;

    constructor (zone: Zone) {
      this.zone = zone
    }

    get displayDates () {
      return this.dates
    }

    get _times (): Map<string, Array<TimeSlot>> {
      return this.times
    }

    get minAsISO () {
      if (this.dates && this.dates.length > 0) {
        return (this.dates[0].value as DateTime).toISODate()
      } else {
        return undefined
      }
    }

    get maxAsISO () {
      if (this.dates && this.dates.length > 0) {
        return (this.dates[this.dates.length - 1].value as DateTime).toISODate()
      } else {
        return undefined
      }
    }

    addLocalDateOption (item: VSelectItemType): void {
      this.dates.push(item)
    }

    updateTimes (dateKey: string, timeSlots: Array<TimeSlot>): void {
      this.times.set(dateKey, timeSlots)
    }

    hasTimesForDate (dateKey: string): boolean {
      return this.times.has(dateKey)
    }

    getTimesForDate (dateKey: string): Array<TimeSlot> {
      return this.times.get(dateKey) ?? []
    }

    removeTimesForDayKey (dayKey: string): void {
      this.dates = this.dates.filter((value: VSelectItemType) => {
        return this.getKeyFromDate(value.value as DateTime) !== dayKey
      })
      this._times.delete(dayKey)
    }

    getKeyFromDate (date: DateTime): string {
      if (!date) {
        throw Error('Date is required')
      }
      if (!this.zone) {
        throw Error('Zone is not set')
      }
      // console.log('getKey: date=', date.toString())
      // console.log('getKey: zone=', this.zone)
      // console.log('getKey: sz =', date.setZone(this.zone).toString())
      return date.setZone(this.zone).set({ hour: 12, minute: 0, second: 0, millisecond: 0 }).toISO()
    }

    getSlotsForKey (dateKey: string): Array<TimeSlot> {
      if (this.times.has(dateKey)) {
        return this.times.get(dateKey) as Array<TimeSlot>
      } else {
        return []
      }
    }

    getSlotsForDate (date: DateTime): Array<TimeSlot> {
      const key = this.getKeyFromDate(date)
      return this.getSlotsForKey(key)
    }

    get timeSlots (): Array<TimeSlot> {
      if (this.selectedDate != null) {
        const key = this.getKeyFromDate(this.selectedDate)
        if (this.times.has(key) && this.times.get(key) != null) {
          return this.times.get(key) as TimeSlot[]
        } else {
          return []
        }
      } else {
        return []
      }
    }

    toString (): string {
      let result: string = 'Availability[' +
        'times=('
      this.times.forEach((value: TimeSlot[], key: string) => {
        result += '{' + key + ': [' + value.map((timeSlot: TimeSlot) => timeSlot.date.toISO()).join(',') + ']}'
      })
      result += '), dates=['
      result += this.dates.map((item: VSelectItemType) => (item.value as DateTime).toISO()).join(',') + ']'
      result += ', selectedDate=' + this.selectedDate
      return result
    }
}
