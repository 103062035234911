
export default class ValidationUtils {
  /**
   * Analyzes a string to check if it has any astral plane characters.
   * @param value - the string to analyze
   * @returns true if the string contains any characters not part of the basic multilingual plane.
   */
  static hasHighUnicode (value: string): boolean {
    if (value) {
      for (const codePoint of Array.from(value)) {
        const code = codePoint.codePointAt(0) ?? -1
        if (code && code > 0xffff) {
          return true
        }
      }
    }
    return false
  }
}
