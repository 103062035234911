


























































































import Vue from 'vue'
import { tourDataService, TourStatus } from '../services/tour-data-service'
import { ConfirmationData } from '@/types/confirmation-data'
import PoweredByButterfly from './PoweredByButterfly.vue'
import AddToCalendarGoogle from './confirmation/AddToCalendarGoogle.vue'
import AddToCalendarIcs from './confirmation/AddToCalendarIcs.vue'
import EntryInstructionPanel from './confirmation/EntryInstructionPanel.vue'
import ErrorDialog from './ErrorDialog.vue'
import { TourUnit } from '@/types/building-info'
import { dateTimeService, ReservationField } from '../services/datetime-service'

export default Vue.extend({
  name: 'ConfirmationPreview',
  components: {
    PoweredByButterfly,
    ErrorDialog,
    AddToCalendarGoogle,
    AddToCalendarIcs,
    EntryInstructionPanel
  },
  data (): ConfirmationData {
    return {
      building: null,
      reservation: null,
      error: false,
      loading: true,
      loaded: false,
      saveError: false,
      saveErrorText: '',
      schedulesEnabled: false,
      fullStyle: 'sgt-card__full'
    }
  },
  inject: {
    dataService: {
      from: 'dataService',
      default: tourDataService
    }
  },
  computed: {
    appointment (): string {
      return dateTimeService.formatReservationTime(this.reservation, this.building)
    },
    appointmentStart (): string {
      return dateTimeService.formatReservationTime(this.reservation, this.building, ReservationField.STARTS_AT, 't')
    },
    appointmentEnd (): string {
      return dateTimeService.formatReservationTime(this.reservation, this.building, ReservationField.ENDS_AT, 't')
    },
    units (): string {
      if (this.reservation?.attributes.tour_units != null) {
        return this.reservation.attributes.tour_units.map((value: TourUnit) => value.label).join(', ')
      } else {
        return ''
      }
    }
  },

  async mounted () {
    console.log('Loading')
    try {
      const handle = this.$route.params.handle
      if (!this.dataService) {
        return
      }
      const buildingResponse = await this.dataService.getBuildingInfo(handle)
      this.reservation = {
        id: '123',
        type: '456',
        attributes: {
          name: 'John Smith',
          email: 'jsmith@example.com',
          // eslint-disable-next-line @typescript-eslint/camelcase
          starts_at: '2021-01-01T12:00:00Z',
          // eslint-disable-next-line @typescript-eslint/camelcase
          ends_at: '2021-01-01T13:00:00Z',
          uuid: '1234',
          status: TourStatus.confirmed,
          // eslint-disable-next-line @typescript-eslint/camelcase
          qr_key_public_url: '',
          // eslint-disable-next-line @typescript-eslint/camelcase
          qr_key_image_url: '@/assets/butteryfly_preview_qr.png',
          pin: '123456',
          // eslint-disable-next-line @typescript-eslint/camelcase
          phone_number: '212-555-1212',
          // eslint-disable-next-line @typescript-eslint/camelcase
          building_handle: handle,
          // eslint-disable-next-line @typescript-eslint/camelcase
          building_name: buildingResponse.attributes.name,
          // eslint-disable-next-line @typescript-eslint/camelcase
          building_address: buildingResponse.attributes.address,
          // eslint-disable-next-line @typescript-eslint/camelcase
          tour_units: [{
            id: 1,
            // eslint-disable-next-line @typescript-eslint/camelcase
            unit_id: 1234,
            description: '1 Bedroom, 1 Bath',
            // eslint-disable-next-line @typescript-eslint/camelcase
            description_2: '875 sq. ft.',
            label: '3F',
            // eslint-disable-next-line @typescript-eslint/camelcase
            key_locker_compartment_cell: 'B2'
          },
          {
            id: 2,
            // eslint-disable-next-line @typescript-eslint/camelcase
            unit_id: 2345,
            description: '2 Bedroom, 2 Bath',
            // eslint-disable-next-line @typescript-eslint/camelcase
            description_2: '1,275 sq. ft.',
            label: '5R',
            // eslint-disable-next-line @typescript-eslint/camelcase
            key_locker_compartment_cell: 'A1'
          }]
        }
      }
      this.building = buildingResponse
      this.schedulesEnabled = this.building?.attributes.enable_scheduling_portal ?? false
      // Update the app bar
      this.$store.commit('saveBuildingInfo', this.building)

      this.loading = false
      this.loaded = true
    } catch (reason) {
      console.warn('Error retrieving api: ', reason)
      this.error = true
      this.loading = false
    }
  }
})
