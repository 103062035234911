import Vue from 'vue'
import VueRouter from 'vue-router'

import Confirmation from './components/Confirmation.vue'
import ConfirmationPreview from './components/ConfirmationPreview.vue'
import ContactAndSchedule from './components/ContactAndSchedule.vue'
import HomePage from './components/HomePage.vue'
import Pending from './components/id_verification/Pending.vue'
import Failed from './components/id_verification/Failed.vue'
import TermsAndConditions from './components/TermsAndConditions.vue'
import TourCanceled from './components/TourCanceled.vue'
import TourNotFound from './components/TourNotFound.vue'
import TourRouter from './components/TourRouter.vue'
import UnitSelection from './components/UnitSelection.vue'
import UnitSelectionPreview from './components/UnitSelectionPreview.vue'
import Verify from './components/Verify.vue'

Vue.use(VueRouter)

export default new VueRouter({
  base: '',
  mode: 'history',
  routes: [
    {
      path: '/',
      component: HomePage,
      name: 'home'
    },
    { path: '/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})', component: TourRouter, name: 'emailrouter' },
    { path: '/:handle([\\w-]+)', component: UnitSelection, name: 'unit_selection' },
    { path: '/:handle/schedule', component: ContactAndSchedule, name: 'schedule' },
    { path: '/:handle/terms', component: TermsAndConditions, name: 'terms' },
    { path: '/:handle/pending', component: Pending, name: 'pending' },
    { path: '/:handle/cancelled', component: Failed, name: 'cancelled' },
    { path: '/:handle/verify', component: Verify, name: 'verify' },
    { path: '/:handle/previewtourpage', component: ConfirmationPreview, name: 'preview' },
    { path: '/:handle/preview-tourable-units', component: UnitSelectionPreview, name: 'unit_preview' },
    { path: '/tour/:id', component: Confirmation, name: 'confirmation' },
    { path: '/tour/error/:id', component: TourNotFound, name: 'tourohfour' },
    { path: '/tour/canceled/:id', component: TourCanceled, name: 'tourcanceled' },
    { path: '*', redirect: '/' }
  ]
})
