



























































import { dateTimeService } from '@/services/datetime-service'
import { GoogleTagEvents } from '@/services/events'
import { BuildingInfo } from '@/types/building-info'
import { RootState } from '@/types/root-state'
import { TourResponse } from '@/types/tour-request-response'
import Vue from 'vue'
import CountdownTimer from './CountdownTimer.vue'
import PoweredByButterfly from './PoweredByButterfly.vue'

export default Vue.extend({
  name: 'Verify',

  components: {
    CountdownTimer,
    PoweredByButterfly
  },

  data: () => ({
    error: false,
    loading: false,
    timedOut: false
  }),

  methods: {
    reschedule () {
      this.$gtag.event(GoogleTagEvents.Reschedule)
      this.$router.push({ name: 'schedule', params: { handle: this.$store.state.buildingInfo?.attributes.handle } })
    }
  },

  computed: {
    reservation (): TourResponse|undefined {
      return (this.$store.state as RootState).reservation
    },
    building (): BuildingInfo|undefined {
      return (this.$store.state as RootState).buildingInfo
    },
    appointment (): string {
      return dateTimeService.formatReservationTime(this.reservation ?? null, this.building ?? null)
    },
    textOrEmail (): string {
      if (this.building) {
        return this.building?.attributes.email_communication ? 'email' : 'SMS'
      } else {
        return ''
      }
    }

  },

  mounted () {
    console.log('Loading')
    if ((this.$store.state as RootState).buildingInfo?.attributes.name == null || (this.$store.state as RootState).reservation?.id == null) {
      // No building info present
      this.error = true
    }
  }
})
