import { VueGtag } from 'vue-gtag'

export const enum GoogleTagEvents {
    Reschedule = 'reschedule',
    IDCanceled = 'id_canceled',
    IDFailed = 'id_failed',
    IDSuccess = 'id_success'
}

export function logBuilding (gtag: VueGtag, handle: string) {
  gtag.set({
    // eslint-disable-next-line @typescript-eslint/camelcase
    user_properties: {
      // eslint-disable-next-line @typescript-eslint/camelcase
      building_handle: handle
    }
  })
}
