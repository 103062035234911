
























import Vue from 'vue'
import PoweredByButterfly from './PoweredByButterfly.vue'

export default Vue.extend({
  name: 'TourNotFound',

  components: {
    PoweredByButterfly
  },

  data: () => ({
  }),

  methods: {
    schedule () {
      this.$router.push({ name: 'home' })
    }
  }
})
