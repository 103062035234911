import 'material-design-icons-iconfont/dist/material-design-icons.css' // Ensure you are using css-loader

import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import EventIcon from '../components/EventIcon.vue'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#51B8B3'
      }
    }
  },
  icons: {
    values: {
      calEvent: { // name of our custom icon
        component: EventIcon // our custom component
      }
    }
  }
})
