
























import { TourResponse } from '@/types/tour-request-response'
import Vue from 'vue'
import PoweredByButterfly from './PoweredByButterfly.vue'

interface TourCanceledData {
  reservation: TourResponse|null;
}

export default Vue.extend({
  name: 'TourCanceled',

  components: {
    PoweredByButterfly
  },

  data (): TourCanceledData {
    return {
      reservation: null
    }
  },

  methods: {
    schedule () {
      if (this.reservation?.attributes?.building_handle) {
        this.$router.push({ name: 'schedule', params: { handle: this.reservation?.attributes?.building_handle } })
      }
    }
  },

  async mounted () {
    if (this.$store.state.reservation) {
      this.reservation = this.$store.state.reservation
    }
  }
})
