import { Availability, TimeSlot } from '@/types/availability'
import { BuildingInfo, BuildingWrapper, DateCountMap, WeekdayMap, UnitKeys, SharedKeys } from '@/types/building-info'
import { UnitInfo } from '@/types/unit-info'
import { TourRequest, TourResponse, TourResponseWrapper } from '@/types/tour-request-response'
import { VSelectItemType } from '@/types/v-select-item-type'
import axios, { AxiosInstance, AxiosResponse } from 'axios'
import { DateTime, IANAZone, Zone } from 'luxon'

export enum TourStatus {
  initial = 'initial', // When a tour has been requested but the user has not yet verified their email address.
  cancelled = 'cancelled', // When its been canceled by the user or the backend.
  confirmed = 'confirmed', // When the user has verified their email
  rescheduled = 'rescheduled', // Also considered confirmed.
  completed = 'completed',
  completedUnused = 'completed_unused'
}

export default class TourDataService {
  private http: AxiosInstance

  constructor (http?: AxiosInstance) {
    this.http = http ?? axios.create({
      baseURL: process.env.VUE_APP_BASE_URL
    })
  }

  async getBuildingInfo (buildingHandle: string): Promise<BuildingInfo> {
    const response: AxiosResponse = await this.http.get(`${buildingHandle}/tour_schedules`)
    return (response.data as BuildingWrapper).data
  }

  async getUnitsInfo (buildingHandle: string, unitIds: Array<number>): Promise<UnitInfo> {
    // eslint-disable-next-line @typescript-eslint/camelcase
    const response: AxiosResponse = await this.http.post(`${buildingHandle}/unit_availabilities`, { tour_unit_ids: unitIds })
    return response.data
  }

  async saveTour (buildingHandle: string, tourRequest: TourRequest): Promise<TourResponse> {
    const response: AxiosResponse = await this.http.post(`${buildingHandle}/tour_schedules`, tourRequest.toJson())
    console.log('response data', response.data)
    return (response.data as TourResponseWrapper).data
  }

  getAllowedDatesFromBuildingInfo (info: BuildingInfo, unitInfo: UnitInfo, selectedUnits: Array<number>): Availability {
    // Force the time to noon just to avoid any weird relative timezone/time shift issues
    // among the relative dates we're working with
    console.log('Local time zone is ', DateTime.local().zoneName, ' ', DateTime.local().offset, ' ', DateTime.local().offsetNameLong)
    console.log('Building time zone is ', info.attributes.time_zone)
    const buildingZone: Zone = IANAZone.create(info.attributes.time_zone)
    const sharedKeyUnits = info.attributes.tour_units.filter(el => selectedUnits.indexOf(el.id) !== -1 && el.shared_key_unit === true)
    if (!buildingZone || !buildingZone.isValid) {
      throw Error('Unable to parse time zone identifier to a valid zone')
    } else {
      console.log('Building time zone is valid')
    }

    const timePeriod = (isNaN(info.attributes.time_window_days)) ? 30 : info.attributes.time_window_days
    console.log('Time period is ', timePeriod)

    const start = DateTime.local().setZone(buildingZone).set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
    const end = DateTime.local().setZone(buildingZone).plus({ days: timePeriod })
    console.log('Date window start: ', start.toISO())
    console.log('Date window end: ', end.toISO())
    const availability = new Availability(buildingZone)

    this.populateWeekdays(availability,
      info.attributes.slots?.weekday_availabilities,
      info.attributes.slot_duration_minutes,
      start,
      end, buildingZone)
    console.log('Pre-filter availability size ', availability.displayDates.length)
    // Now we have a complete list of time slots for all the time windows, but it hasn't been filtered
    // for any fully booked slots, or any times prohibited by the property.

    // Filter for max allowed bookings
    const bookedTimes: DateCountMap | undefined = info.attributes.slots?.scheduled_slots
    const reservedTimes: DateCountMap | undefined = info.attributes.slots?.reserved_slots
    const maxBookings = info.attributes.max_simultaneous_bookings
    const mergedTimes = this.mergeSlots(bookedTimes, reservedTimes)
    const sharedKeyCompartmentCount = info.attributes.shared_key_compartment_count
    this.filterReservedTimes(availability, mergedTimes, maxBookings)
    this.filterReservedTimesByUnit(availability, unitInfo.unavailableUnitTimes)
    if (sharedKeyUnits.length > 0 && info.attributes.key_locker_in_use) {
      this.filterSharedKeyCompartments(availability, mergedTimes, sharedKeyCompartmentCount)
    }

    this.filterPastTimes(availability, start, buildingZone)

    // Once we have filtered all the times, now remove an dates that have no time slots.
    this.filterEmptyDays(availability)

    return availability
  }

  getShortKey (date: DateTime): string {
    return date.setLocale('en-US').weekdayShort.toLowerCase()
  }

  mergeSlots (scheduledSlots: DateCountMap|undefined, reservedSlots: DateCountMap|undefined): DateCountMap {
    if (!scheduledSlots) {
      if (reservedSlots) {
        return reservedSlots
      } else {
        return {}
      }
    } else if (!reservedSlots) {
      return scheduledSlots
    } else {
      const result: DateCountMap = {}
      const data = [scheduledSlots, reservedSlots]
      data.forEach((dateCountMap: DateCountMap) => {
        for (const [key, value] of Object.entries(dateCountMap)) {
          if (result[key]) {
            result[key] += value
          } else {
            result[key] = value
          }
        }
      })
      return result
    }
  }

  populateWeekdays (availability: Availability, weekdayMap: WeekdayMap | undefined, slotDurationMinutes: number,
    start: DateTime, end: DateTime, offsetZone: Zone) {
    let date: DateTime = start
    while (date < end) {
      const shortKey = this.getShortKey(date)
      if (weekdayMap &&
        weekdayMap[shortKey] != null) {
        const timeRangesPerDay = weekdayMap[shortKey]
        availability.addLocalDateOption(this.makeSelectItemFromDate(date, offsetZone))

        const dateKey: string = availability.getKeyFromDate(date)
        availability.updateTimes(dateKey, this.makeTimeSlots(date, timeRangesPerDay, slotDurationMinutes, offsetZone))
      }
      date = date.plus({ hours: 24 })
    }
  }

  filterPastTimes (availability: Availability, start: DateTime, buildigZone: Zone): void {
    const key = availability.getKeyFromDate(start)
    if (availability.hasTimesForDate(key)) {
      const timeSlotsForDay: Array<TimeSlot> = availability.getTimesForDate(key)
      if (timeSlotsForDay) {
        const now = DateTime.local().setZone(buildigZone).plus({ hours: 1 })
        const filteredTimeSlots = timeSlotsForDay.filter((timeSlot: TimeSlot) => {
          return timeSlot.date > now
        })
        availability.updateTimes(key, filteredTimeSlots)
      }
    }
  }

  filterReservedTimes (availability: Availability, bookedDates: DateCountMap | undefined, maxBookings: number | undefined) {
    if (bookedDates && maxBookings) {
      for (const [key, value] of Object.entries(bookedDates)) {
        if (value >= maxBookings) {
          this.filterTimes(key, availability)
        }
      }
    }
  }

  filterReservedTimesByUnit (availability: Availability, unavailableUnitTimes: Array<string>) {
    unavailableUnitTimes.forEach((date: string) => {
      this.filterTimes(date, availability)
    })
  }

  filterSharedKeyCompartments (availability: Availability, bookedDates: DateCountMap | undefined, sharedKeyCompartmentCount: number | undefined) {
    if (bookedDates && sharedKeyCompartmentCount) {
      for (const [key, value] of Object.entries(bookedDates)) {
        if (value >= sharedKeyCompartmentCount) {
          this.filterTimes(key, availability)
        }
      }
    }
  }

  filterTimes (date: string, availability: Availability) {
    const aBooking = DateTime.fromISO(date, { setZone: true })
    const dayOfBooking: string = availability.getKeyFromDate(aBooking)
    if (availability.hasTimesForDate(dayOfBooking)) {
      const timeSlotsForDay: Array<TimeSlot> | undefined = availability.getTimesForDate(dayOfBooking)
      if (timeSlotsForDay) {
        const filteredTimeSlots = timeSlotsForDay.filter((timeSlot: TimeSlot) => {
          return +timeSlot.date !== +aBooking
        })
        availability.updateTimes(dayOfBooking, filteredTimeSlots)
      }
    }
  }

  filterEmptyDays (availability: Availability): void {
    availability.displayDates.forEach((selectItem: VSelectItemType) => {
      const day = selectItem.value as DateTime
      const dayKey: string = availability.getKeyFromDate(day)
      const timeSlotsForDay: Array<TimeSlot> = availability.getTimesForDate(dayKey)
      if (timeSlotsForDay && timeSlotsForDay.length === 0) {
        availability.removeTimesForDayKey(dayKey)
      }
    })
  }

  makeTimeSlots (dateForDayComponent: DateTime, ranges: Array<Array<string>>, intervalInMinutes: number,
    offsetZone: Zone): Array<TimeSlot> {
    const times: Map<string, DateTime> = new Map()
    ranges.forEach((range: Array<string>) => {
      const start: string = range[0]
      const end: string = range[1]
      const startTime: DateTime = DateTime.fromISO(start, { zone: offsetZone }).set({
        year: dateForDayComponent.year,
        month: dateForDayComponent.month,
        day: dateForDayComponent.day
      })
      const endTime: DateTime = DateTime.fromISO(end, { zone: offsetZone }).set({
        year: dateForDayComponent.year,
        month: dateForDayComponent.month,
        day: dateForDayComponent.day
      })
      this._generateTimes(startTime, endTime, intervalInMinutes, times)
    })
    // Sort because keys would be on insertion order and ranges can be in any order of start time
    return this._uniqueTimeArrayFromMap(times).sort((a: TimeSlot, b: TimeSlot) => {
      return +a.date - +b.date
    })
  }

  _uniqueTimeArrayFromMap (times: Map<string, DateTime>): Array<TimeSlot> {
    const uniqueTimes: Array<string> = Array.from(times.keys())
    return uniqueTimes.map((key: string) => {
      return { date: times.get(key) ?? DateTime.local(), display: key }
    })
  }

  _generateTimes (startTime: DateTime, endTime: DateTime, intervalInMinutes: number, times: Map<string, DateTime>): void {
    let currentTime: DateTime = startTime

    // Subtracting one minute so we don't get off by one errors
    while (currentTime.plus({ minutes: intervalInMinutes }).minus({ minutes: 1 }) <= endTime) {
      const displayKey = this._getDisplayKey(currentTime)
      if (!times.has(displayKey)) {
        times.set(displayKey, currentTime)
      }
      currentTime = currentTime.plus({ minutes: intervalInMinutes })
    }
  }

  _getDisplayKey (currentTime: DateTime): string {
    return currentTime.toLocaleString(DateTime.TIME_SIMPLE)
  }

  makeSelectItemFromDate (date: DateTime, buildingZone: Zone): VSelectItemType {
    const isToday: boolean = date.hasSame(DateTime.local().setZone(buildingZone), 'day')
    return {
      text: isToday ? 'Today ' + date.toFormat('MMM d') : date.toFormat('EEEE, MMM d'),
      value: date,
      disabled: false
    }
  }

  getAllKeys (reservation: TourResponse|null) {
    const unitKeys: Array<UnitKeys> = []
    const sharedKeys: SharedKeys = { units: [], keychain: null }

    if (reservation) {
      reservation.attributes.tour_units.forEach((unit) => {
        let keyFound = false

        if (reservation.attributes.keychains) {
          reservation.attributes.keychains.forEach((keychain) => {
            if (unit.unit_id === keychain.unit_id) {
              unitKeys.push({
                unit: unit,
                keychain: keychain
              })
              keyFound = true
              return
            }
            if (keychain.unit_id === null) {
              sharedKeys.keychain = keychain
            }
          })
        }
        if (!keyFound) {
          sharedKeys.units.push(unit)
        }
      })
      return { unitKeys: unitKeys, sharedKeys: sharedKeys }
    }
  }

  async getTourInfo (tourId: string): Promise<TourResponse> {
    const response: AxiosResponse = await this.http.get(`${tourId}`)
    return (response.data as TourResponseWrapper).data
  }

  async confirmTour (tourId: string): Promise<TourResponse> {
    const confirmPayload = '{"data":{}}'
    const response: AxiosResponse = await this.http.patch(`${tourId}`, JSON.parse(confirmPayload))
    // New response will reflect the new status
    return (response.data as TourResponseWrapper).data
  }

  async updateTour (tourId: string, requestedTime: string): Promise<TourResponse> {
    const updatePayload = {
      data: {
        attributes: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          starts_at: requestedTime
        }
      }
    }
    const response: AxiosResponse = await this.http.patch(`${tourId}`, updatePayload)
    // New response will reflect the new time
    return (response.data as TourResponseWrapper).data
  }

  async cancelTour (tourId: string): Promise<TourResponse> {
    const response: AxiosResponse = await this.http.delete(`${tourId}`)
    return (response.data as TourResponseWrapper).data
  }
}

// A singleton instance
export const tourDataService = new TourDataService()
