













import Vue from 'vue'
import { BuildingInfo } from './types/building-info'

interface AppData {
  drawer: boolean;
  unsubscribe?: () => void;
  appTitle?: string;
}

export default Vue.extend({
  name: 'App',

  data (): AppData {
    return {
      drawer: false,
      appTitle: ''
    }
  },
  created () {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'saveBuildingInfo') {
        this.appTitle = (state.buildingInfo as BuildingInfo)?.attributes?.name
      }
    })
  },
  beforeDestroy () {
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  }
})
