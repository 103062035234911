import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import { BuildingInfo, TourUnit } from './types/building-info'
import { RootState } from './types/root-state'
import { TourRequest, TourResponse } from './types/tour-request-response'

Vue.use(Vuex)

const store: StoreOptions<RootState> = {
  state: {
    interestedUnits: []
  } as RootState,
  mutations: {
    saveReservation (state, reservation: TourResponse) {
      state.reservation = reservation
    },
    saveBuildingInfo (state, buildingInfo: BuildingInfo) {
      state.buildingInfo = buildingInfo
    },
    saveTourRequest (state, request: TourRequest) {
      state.request = request
    },
    saveInterestedUnits (state, interestedUnits: Array<TourUnit>) {
      state.interestedUnits = interestedUnits
    }
  }
}

export default new Vuex.Store<RootState>(store)
