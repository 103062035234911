



























import { logBuilding } from '@/services/events'
import TourDataService, { tourDataService, TourStatus } from '@/services/tour-data-service'
import { BuildingInfo } from '@/types/building-info'
import { TourResponse } from '@/types/tour-request-response'
import Vue from 'vue'
import ErrorDialog from './ErrorDialog.vue'

interface TourRouterData {
  building: BuildingInfo|null;
  reservation: TourResponse|null;
  error: boolean;
  loading: boolean;
  loaded: boolean;
  saveError: boolean;
  saveErrorText: string;
  dataService?: TourDataService;
}

export default Vue.extend({
  name: 'TourRouter',

  components: {
    ErrorDialog
  },

  data (): TourRouterData {
    return {
      building: null,
      reservation: null,
      error: false,
      loading: true,
      loaded: false,
      saveError: false,
      saveErrorText: ''
    }
  },
  inject: {
    dataService: {
      from: 'dataService',
      default: tourDataService
    }
  },
  async mounted () {
    console.log('Loading')
    const tourId: string = this.$route.params.id
    try {
      // Load Tour Data
      if (!this.dataService) {
        console.error('No data service')
        return
      }
      const tourResponse = await this.dataService.getTourInfo(`${tourId}`)
      // console.log('Loaded, info=', tourResponse)
      this.reservation = tourResponse
      this.$store.commit('saveReservation', tourResponse)

      // Have we already loaded building info from a previous navigation?
      if (!this.$store.state.buildingInfo?.attributes?.name) {
        // No, load the building data too.
        const buildingResponse = await this.dataService.getBuildingInfo(tourResponse.attributes.building_handle)
        this.building = buildingResponse
        // Update the app bar
        this.$store.commit('saveBuildingInfo', this.building)
      } else {
        // Yes, restore it from memory
        this.building = this.$store.state.buildingInfo
      }
      logBuilding(this.$gtag, this.building?.attributes.handle ?? 'unset')

      if (tourResponse.attributes.status === TourStatus.cancelled) {
        console.log('Tour canceled, going to cancel page')
        this.$router.replace({ name: 'tourcanceled', params: { id: tourId } })
      } else {
        if (tourResponse.attributes.status === TourStatus.initial) {
          const updatedResponse = await this.dataService.confirmTour(tourId)
          this.$store.commit('saveReservation', updatedResponse)
        }
        this.$router.replace({ name: 'confirmation', params: { id: tourId } })
      }
      this.loaded = true
    } catch (reason) {
      console.warn('Error retrieving api: ', reason)
      this.error = true
      this.$router.replace({ name: 'tourohfour', params: { id: tourId } })
    } finally {
      this.loading = false
    }
  }
})
