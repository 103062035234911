
















































































import { tourDataService } from '@/services/tour-data-service'
import { UnitSelectionData } from '@/types/unit-selection-data'
import { AxiosError } from 'axios'
import Vue from 'vue'
import PoweredByButterfly from './PoweredByButterfly.vue'
import { BuildingInfo, TourUnit } from '@/types/building-info'

export default Vue.extend({
  name: 'UnitSelectionPreview',

  components: {
    PoweredByButterfly
  },

  data (): UnitSelectionData {
    return {
      building: null,
      error: false,
      loading: true,
      loaded: false,
      selected: [],
      previewUnits: []
    }
  },

  async mounted () {
    window.addEventListener('message', (event) => {
      if (event.origin === 'https://usertest.butterflymx.com' ||
      event.origin === 'https://user.butterflymx.com') {
        console.log('event data', event.data)
        console.log('event origin', event.origin)

        if (event.data) {
          // Note event.data is not a proper array, so we need to convert it first.
          const units: Array<TourUnit> = Object.values(event.data)
          this.previewUnits = units.map((unit: TourUnit): TourUnit => {
            // The image links come with some aws credentials and parameters that break the image loading,
            // so we remove the query string
            if (unit && unit.image_url) {
              // eslint-disable-next-line @typescript-eslint/camelcase
              unit.image_url = unit.image_url.split('?')[0]
            }
            return unit
          })
        }
      } else {
        console.log('Ignoring non-origin message from ', event.origin)
      }
    }, false)
    if (window.opener) {
      window.opener.postMessage('previewOpened', '*')
    }

    if (!this.building?.id) {
      await tourDataService.getBuildingInfo(this.$route.params.handle)
        .then((response: BuildingInfo) => {
          this.building = response
          this.loading = false
          this.loaded = true
          this.$store.commit('saveBuildingInfo', this.building)
          if (process.env.VUE_APP_ENV === 'development') {
            this.previewUnits = this.building.attributes.tour_units
            // eslint-disable-next-line @typescript-eslint/camelcase
            this.previewUnits.forEach((unit: TourUnit) => { unit.image_url = 'https://picsum.photos/500/300' })
          }
        })
        .catch((reason: AxiosError) => {
          console.error('Error retrieving building: ', reason)
          this.error = true
          this.loading = false
        })
    } else {
      this.loading = false
      this.loaded = true
    }
  }
})
