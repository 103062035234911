import { SetupIntent } from '@stripe/stripe-js'
import { TourUnit, Keychain } from './building-info'
import { PersonaPACBlob, PersonaResponse } from './persona'

export interface TourRequestWrapper {
  data: TourRequestData;
}

export interface TourRequestData {
  attributes: TourRequestAttributes;
}

export interface TourRequestAttributes {
  name: string;
  email: string;
  phone_number: string;
  starts_at: string;
  tour_unit_ids: Array<number>;
  persona?: PersonaPACBlob;
  stripe?: SetupIntent;
}

export class TourRequest {
  name: string;
  email: string;
  phoneNumber: string;
  startsAt: string;
  tourUnits: Array<TourUnit>;
  idVerificationBlob?: PersonaResponse;
  stripeIntent?: SetupIntent

  constructor (name: string, email: string, phoneNumber: string, startsAt: string, tourUnits: Array<TourUnit>,
    idVerificationBlob?: PersonaResponse, stripeIntent?: SetupIntent) {
    this.name = name
    this.email = email
    this.phoneNumber = phoneNumber
    this.startsAt = startsAt
    this.tourUnits = tourUnits
    this.idVerificationBlob = idVerificationBlob
    this.stripeIntent = stripeIntent
  }

  generatePersonaPayload (idVerificationBlob: PersonaResponse|undefined): PersonaPACBlob|undefined {
    if (!idVerificationBlob) {
      return undefined
    }
    return {
      inquiryId: idVerificationBlob.inquiryId,
      name: idVerificationBlob.scopes?.name,
      address: idVerificationBlob.scopes?.address,
      birthdate: idVerificationBlob.scopes?.birthdate
    }
  }

  toJson (): TourRequestWrapper {
    return {
      data: {
        attributes: {
          name: this.name,
          email: this.email,
          // eslint-disable-next-line @typescript-eslint/camelcase
          phone_number: this.phoneNumber,
          // eslint-disable-next-line @typescript-eslint/camelcase
          starts_at: this.startsAt,
          // eslint-disable-next-line @typescript-eslint/camelcase
          tour_unit_ids: this.tourUnits.map((value: TourUnit) => value.id),
          persona: this.generatePersonaPayload(this.idVerificationBlob),
          stripe: this.stripeIntent
        }
      }
    }
  }
}

/**
 *  Example
{
  "data": {
    "id": "3",
    "type": "tour_schedules",
    "attributes": {
      "name": "Vedran Rodic",
      "email": "vedran.rodic@butterflymx.com",
      "starts_at": "2020-12-22T13:00:00Z",
      "ends_at": "2020-12-22T13:30:00Z",
      "uuid": "3508f201-6b11-4dfc-95b5-ef337e78debf",
      "status": "initial",
      "qr_key_public_url": null,
      "pin": null,
      "qr_key_image_url": null,
      "building_handle": "alex-zack-building"
    }
  }
}
*/
export interface TourResponseWrapper {
  data: TourResponse;
}

export interface TourResponse {
  id: string;
  type: string; // "tour_schedules"
  attributes: TourAttributes;
}

export interface TourAttributes {
    name: string;
    email: string;
    starts_at: string;
    ends_at: string;
    uuid: string;
    status: string;
    qr_key_public_url: string;
    pin: string;
    qr_key_image_url: string;
    phone_number: string;
    building_handle: string;
    building_name: string;
    building_address: Address;
    tour_units: Array<TourUnit>;
    keychains?: Array<Keychain> | null;
  }

export interface Address {
    address_1: string;
    address_2: string;
    city: string;
    country: string;
    phone_number: string;
    state: string;
    zip_code: string;
  }

export interface StringMap {
    [key: string]: string;
  }

export interface DateCountMap {
  [key: string]: number;
}
export interface Schedule {
// TODO    weekday_availabilities?: Weekdays;
    weekday_availabilities?: WeekdayMap;
    scheduled_slots: DateCountMap;
    reserved_slots: DateCountMap;
    blacked_out_slots: Array<string>;
}
export interface WeekdayMap {
  [key: string]: Array<Array<string>>;
}

export interface Weekdays {
  mon?: Array<Array<string>>;
  tue?: Array<Array<string>>;
  wed?: Array<Array<string>>;
  thu?: Array<Array<string>>;
  fri?: Array<Array<string>>;
  sat?: Array<Array<string>>;
  sun?: Array<Array<string>>;
}
