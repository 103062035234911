










































import Vue from 'vue'
import PoweredByButterfly from '../PoweredByButterfly.vue'

export default Vue.extend({
  name: 'Pending',

  components: {
    PoweredByButterfly
  },

  methods: {
    reschedule () {
      console.log('reschedule')
      this.$router.push({ name: 'schedule', params: { handle: this.$route.params.handle } })
    },
    restartVerification () {
      this.$router.push({
        name: 'terms',
        params: {
          handle: this.$route.params.handle ?? this.$store.state.buildingInfo?.attributes.handle,
          autostart: 'true'
        }
      })
    }
  }

})
