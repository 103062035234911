

















































































import { tourDataService } from '@/services/tour-data-service'
import { UnitSelectionData } from '@/types/unit-selection-data'
import { AxiosError } from 'axios'
import Vue from 'vue'
import ErrorDialog from '../components/ErrorDialog.vue'
import PoweredByButterfly from './PoweredByButterfly.vue'
import { BuildingInfo, TourUnit } from '@/types/building-info'
import { logBuilding } from '@/services/events'

export default Vue.extend({
  name: 'UnitSelection',

  components: {
    PoweredByButterfly,
    ErrorDialog
  },

  data (): UnitSelectionData {
    return {
      building: null,
      error: false,
      loading: true,
      loaded: false,
      selected: []
    }
  },

  computed: {
    continueDisabled (): boolean {
      return this.loading || this.error || !this.selected || this.selected.length < 1
    }
  },
  methods: {
    async onClick () {
      this.loading = true
      const interestedUnits = this.building?.attributes.tour_units.filter((value: TourUnit, index: number) => {
        return this.selected.indexOf(index) > -1
      })
      this.$store.commit('saveInterestedUnits', interestedUnits)
      this.$router.push({ name: 'schedule', params: { id: this.$route.params.handle } })
    }
  },

  async mounted () {
    this.building = this.$store.state.buildingInfo
    if (!this.building?.id) {
      await tourDataService.getBuildingInfo(this.$route.params.handle)
        .then((response: BuildingInfo) => {
          logBuilding(this.$gtag, response.attributes.handle)
          this.building = response
          this.loading = false
          this.loaded = true
          this.$store.commit('saveBuildingInfo', this.building)
          if (!this.building.attributes.enable_scheduling_portal) {
            this.error = true
          } else
          if (!this.building.attributes.unit_selection || this.building.attributes.tour_units.length === 0) {
            this.$router.replace({ name: 'schedule', params: { id: this.$route.params.handle } })
          }
        })
        .catch((reason: AxiosError) => {
          console.error('Error retrieving building: ', reason)
          this.error = true
          this.loading = false
        })
    } else {
      this.loading = false
      this.loaded = true
    }
  }
})
