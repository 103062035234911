import axios, { AxiosInstance, AxiosResponse } from 'axios'
import { loadStripe, SetupIntent, SetupIntentResult, Stripe, StripeCardElement, StripeElementStyle } from '@stripe/stripe-js'
import { CreditCardInfo } from '@/types/stripe'

export default class StripeService {
    stripe: Stripe|null = null;
    card: StripeCardElement|null = null;
    intent: SetupIntent|null = null;
    key: string|null = null;
    http: AxiosInstance;

    constructor (http?: AxiosInstance) {
      this.http = http ?? axios.create({
        baseURL: process.env.VUE_APP_BASE_URL
      })
    }

    async getKeyAndIntent (): Promise<CreditCardInfo> {
      const response: AxiosResponse = await this.http.post('credit_card')
      const info: CreditCardInfo = response.data as CreditCardInfo
      this.intent = info.setup_intent
      this.key = info.public_key
      return (response.data as CreditCardInfo)
    }

    async stripeElements (publicKey: string, elementId = '#card-element'): Promise<void> {
      console.log('stripeElements')
      this.stripe = await loadStripe(publicKey, { apiVersion: '2020-08-27' })
      if (!this.stripe) {
        console.error('Could not load stripe')
        return
      }
      const elements = this.stripe.elements()

      // Element styles
      const style: StripeElementStyle = {
        base: {
          fontSize: '14px',
          color: '#000000',
          fontFamily: 'Inter, sans-serif',
          fontSmoothing: 'antialiased',
          fontWeight: 300,
          '::placeholder': {
            color: 'rgba(0,0,0,0.4)'
          }
        }
      }

      const card = elements.create('card', { style: style })
      console.log('Mounting element')
      card.mount(elementId)

      // Element focus ring
      card.on('focus', () => {
        const el = document.getElementById('card-element')
        if (el) {
          el.classList.add('focused')
        }
      })

      card.on('blur', function () {
        const el = document.getElementById('card-element')
        if (el) {
          el.classList.remove('focused')
        }
      })
      this.card = card
    }

    // Handle payment submission when user clicks the pay button.
    async linkCard (email: string): Promise<SetupIntentResult|null> {
      if (!this.stripe || !this.card) {
        console.error('Uh oh, no stripe API or card element.')
        return null
      }
      const result: SetupIntentResult = await this.stripe.confirmCardSetup(this.intent?.client_secret ?? '', {
        // eslint-disable-next-line @typescript-eslint/camelcase
        payment_method: {
          card: this.card,
          // eslint-disable-next-line @typescript-eslint/camelcase
          billing_details: { email: email }
        }
      })

      if (!result.error) {
        // The PaymentMethod was successfully set up
        await this._orderComplete(this.stripe, this.intent?.client_secret ?? null)
      }
      return result
    }

    async _orderComplete (stripe: Stripe|null, clientSecret: string|null): Promise<SetupIntentResult|string> {
      if (!clientSecret) {
        return 'No client secret available'
      }
      if (!this.stripe) {
        return 'Stripe api not available'
      }
      return await this.stripe.retrieveSetupIntent(clientSecret)

      // Sample result:
      // {
      //   "id": "seti_1IQDYcJajfbLOlggDvHF5DUG",
      //   "object": "setup_intent",
      //   "cancellation_reason": null,
      //   "client_secret": "seti_1IQDYcJajfbLOlggDvHF5DUG_secret_J2I8ya7Ac9hktiPzsW1LEtb6AfBizG0",
      //   "created": 1614612074,
      //   "description": null,
      //   "last_setup_error": null,
      //   "livemode": false,
      //   "next_action": null,
      //   "payment_method": "pm_1IQE3DJajfbLOlggZSQzUQzv",
      //   "payment_method_types": [
      //     "card"
      //   ],
      //   "status": "succeeded",
      //   "usage": "off_session"
      // }
    }
}
// A singleton instance
export const stripeService = new StripeService()
