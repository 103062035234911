import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import VueICSPlugin, { VueICSOptions } from 'vue-ics'
import AddToCalendar from 'vue-add-to-calendar'
import Vuetify from 'vuetify'
import VueGtag from 'vue-gtag'
import { updateWindowProperties } from './services/window-utils'
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib'

updateWindowProperties()

Vue.config.productionTip = false

const calendarOptions: VueICSOptions = {
  uidDomain: 'butterflymx.com',
  prodId: 'selftours'
}

Vue.use(VueTelInputVuetify, {
  vuetify
})

Vue.use(VueICSPlugin, calendarOptions)
Vue.use(AddToCalendar)

// SGT-85 Patch to add-to-calendar to fix google calendar link
AddToCalendar.data().calendars.google.url =
  'http://www.google.com/calendar/render?action=TEMPLATE&trp=false'
Vue.use(Vuetify)

Vue.use(
  VueGtag,
  {
    config: { id: 'G-3B2EVQ7QFY' }
  },
  router
)

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')

console.log('Self Guided Tours Version ', process.env.VUE_APP_CURRENT_GIT_SHA)
