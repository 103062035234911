








































import Vue from 'vue'

export default Vue.extend({
  name: 'EntryInstructitonPanel',
  props: {
    propAllKeys: Object
  }
})
