















import { TourResponse } from '@/types/tour-request-response'
import { DateTime } from 'luxon'
import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'AddToCalendarGoogle',
  props: {
    reservation: Object as PropType<TourResponse>
  },
  computed: {
    start (): Date {
      return DateTime.fromISO(this.reservation.attributes.starts_at).toJSDate()
    },
    end (): Date {
      return DateTime.fromISO(this.reservation.attributes.ends_at).toJSDate()
    },
    title (): string {
      return 'Self-guided tour at ' + this.reservation.attributes.building_name
    },
    details (): string {
      return 'Self-guided tour at ' + this.reservation.attributes.building_name
    },
    location (): string {
      return this.reservation.attributes.building_address.address_1 + ', \n' +
      this.reservation.attributes.building_address.address_2
    }
  }
})
