

























import Vue from 'vue'
import PoweredByButterfly from '../PoweredByButterfly.vue'

export default Vue.extend({
  name: 'Pending',

  components: {
    PoweredByButterfly
  }

})
