















































































































































































import Vue from 'vue'
import { tourDataService, TourStatus } from '../services/tour-data-service'
import { BuildingInfo, TourUnit } from '@/types/building-info'
import { UnitInfo } from '@/types/unit-info'
import { AxiosError } from 'axios'
import { DateTime, FixedOffsetZone } from 'luxon'
import { ContactAndScheduleData } from '@/types/contact-and-schedule-data'
import PoweredByButterfly from './PoweredByButterfly.vue'
import { Availability } from '@/types/availability'
import { TourRequest, TourResponse } from '@/types/tour-request-response'
import ErrorDialog from './ErrorDialog.vue'
import { ServerError } from '@/types/errors'
import { RootState } from '@/types/root-state'
import { VSelectItemType } from '@/types/v-select-item-type'
import { stripeService } from '@/services/stripe-service'
import { CreditCardInfo } from '@/types/stripe'
import { SetupIntent, SetupIntentResult } from '@stripe/stripe-js'
import ValidationUtils from '@/services/validation-utils'
import { logBuilding } from '@/services/events'

export default Vue.extend({
  name: 'ContactAndSchedule',
  components: {
    PoweredByButterfly,
    ErrorDialog
  },
  data (): ContactAndScheduleData {
    return {
      info: null,
      reservation: null,
      error: false,
      saveError: false,
      saveErrorText: '',
      loading: true,
      valid: false,
      selectedTimeSlot: undefined,
      personName: '',
      phoneNumber: '',
      showTimeSlots: false,
      nameRules: [
        (v: string) => (!!v && v.trim().length > 0 && v.split(' ').length > 1) || 'First and last name are required',
        (v: string) => (!!v && v.trim().length < 100) || 'Name must be less than 100 characters.',
        (v: string) => !ValidationUtils.hasHighUnicode(v) || 'Please do not use special characters'
      ],
      email: '',
      emailRules: [
        (v: string) => !!v || 'Email is required',
        (v: string) => /[^\s]+@[^\s]+/.test(v) || 'Email must be valid',
        (v: string) => !ValidationUtils.hasHighUnicode(v) || 'Please do not use special characters'
      ],
      phoneRules: [
        (v: string) => !!v || 'Phone number is required',
        (v: string) => (/[\d]+/.test(v) && !ValidationUtils.hasHighUnicode(v)) || 'Phone number must be valid'
      ],
      availability: new Availability(FixedOffsetZone.utcInstance),
      selectedUnits: [],
      allowedDates: [],
      selectedDate: DateTime.invalid('default'),
      currentDateModel: {
        text: ''
      } as VSelectItemType,
      dateMenuActive: false,
      datePickerModel: '',
      useCredit: false
    }
  },

  computed: {
    activeDateDisplay (): string {
      return this.currentDateModel?.text as string ?? ''
    },
    countries (): Array<string> {
      // Example: return ['BS', 'BM', 'CA', 'CL', 'CO', 'MX', 'PE', 'US']
      return ['US']
    },
    preferredCountries (): Array<string> {
      return ['US']
    }
  },

  methods: {
    onFocus (): void {
      console.log('focus....')
      this.$nextTick(() => {
        (this.$refs.telInput as HTMLElement).focus()
      })
    },
    onTimeChange (): void {
      console.log('Time change, selectedTimeSlot = ' + this.selectedTimeSlot?.display)
    },
    onSubmit (): void {
      // noop to avoid firefox submitting form by bubbling the submit event from the stripe card element
    },
    isAllowedDate (val: string): VSelectItemType|undefined {
      return this.allowedDates.find((value: VSelectItemType) => ((value.value as DateTime).toISODate() === val))
    },
    async reserve (): Promise<void> {
      if (this.valid) {
        this.loading = true
        this.saveError = false
        let intentResult: SetupIntentResult|null = null
        try {
          if (!this.selectedTimeSlot) {
            this.saveError = true
          } else {
            const slotAsString: string = this.selectedTimeSlot?.date.toISO()
            if (this.reservation?.id && this.reservation.attributes.status !== TourStatus.cancelled &&
              this.reservation.attributes.status !== TourStatus.initial) {
            // Workflow: Modifying an existing reservation
              console.log('Modify, status=', this.reservation.attributes.status)
              const updatedResponse = await tourDataService.updateTour(this.reservation.attributes.uuid, slotAsString)
              this.$store.commit('saveReservation', updatedResponse)
              // Note: This workflow will become relevant under SGT-94
              // if (this.reservation.attributes.status !== TourStatus.confirmed) {
              //   // Note they already saw the terms if they are rescheduling
              //   this.$router.push({ name: 'verify', params: { handle: this.$route.params.handle } })
              // } else {
              this.$router.push({ name: 'confirmation', params: { id: this.reservation.attributes.uuid } })
            // }
            } else {
              let setupIntent: SetupIntent|undefined
              if (this.useCredit) {
                intentResult = await stripeService.linkCard(this.email)
                console.log('intent result', intentResult)
                if (intentResult?.error) {
                  console.error('Intent result has error')
                  this.saveError = true
                  this.saveErrorText = intentResult.error.message ?? intentResult.error.type.toString()
                } else {
                  setupIntent = intentResult?.setupIntent
                }
              } else {
                const existingRequest: TourRequest = (this.$store.state as RootState).request as TourRequest
                if (existingRequest?.stripeIntent) {
                  setupIntent = existingRequest.stripeIntent
                }
              }
              if (!this.saveError) {
                const units: Array<TourUnit> = (this.$store.state as RootState).interestedUnits as Array<TourUnit>
                // Workflow: Creating a new reservation in memory, navigate to Terms page for later saving.
                const request: TourRequest = new TourRequest(this.personName, this.email, this.phoneNumber, slotAsString, units, undefined, setupIntent)
                this.$store.commit('saveTourRequest', request)
                this.$router.push({ name: 'terms', params: { handle: this.$route.params.handle } })
              } else {
                console.error('Staying on page due to error')
              }
            }
          }
        } catch (e) {
          this.saveError = true
          if (e.isAxiosError) {
            const axiosError: AxiosError = e as AxiosError
            const serverError: ServerError = axiosError.response?.data
            if (serverError?.errors?.length > 0) {
              this.saveErrorText = e.message + ': ' + serverError.errors[0].detail
            } else {
              this.saveErrorText = e.message + ' (no detail)'
            }
          } else {
            this.saveErrorText = e.message
          }
        } finally {
          this.loading = false
        }
      }
    },
    onDateChange (selectedDate: string): void {
      const selectedItem: VSelectItemType|undefined = this.availability.displayDates.find((value: VSelectItemType) => ((value.value as DateTime).toISODate() === selectedDate))

      this.dateMenuActive = false
      if (selectedItem) {
        this.currentDateModel = selectedItem
        this.selectedDate = selectedItem.value as DateTime
        this.availability.selectedDate = this.selectedDate
        this.showTimeSlots = true
      }
      this.selectedTimeSlot = undefined // clear selectedTimeSlot since date changed
    },
    useDefaultDate (): void {
      if (this.availability.displayDates && this.availability.displayDates.length > 0) {
        this.currentDateModel = this.availability.displayDates[0]
        this.availability.selectedDate = this.currentDateModel.value as DateTime
        this.showTimeSlots = true
      } else {
        this.showTimeSlots = false
      }
    },
    checkMissingUnits (units? : TourUnit[]): boolean {
      if (this.info?.attributes.unit_selection && this.info?.attributes.tour_units.length > 0) {
        const interestedUnits = (this.$store.state as RootState).interestedUnits
        const hasInterestedUnits = interestedUnits && interestedUnits.length > 0
        const hasReservationUnits = units && units.length > 0
        if (!hasInterestedUnits && !hasReservationUnits) {
          this.$router.replace({ name: 'unit_selection', params: { handle: this.$route.params.handle } })
          return true
        }
      }
      return false
    }

  },

  async mounted (): Promise<void> {
    console.log('Loading')
    this.useCredit = false
    const existingReservation: TourResponse = (this.$store.state as RootState).reservation as TourResponse
    this.selectedUnits = (this.$store.state as RootState).interestedUnits.map(unit => unit.id)
    try {
      const response: BuildingInfo = await tourDataService.getBuildingInfo(this.$route.params.handle)
      const responseUnit: UnitInfo = await tourDataService.getUnitsInfo(this.$route.params.handle, this.selectedUnits)
      logBuilding(this.$gtag, response.attributes.handle)
      console.log('Loaded, info=', response)
      this.info = response

      if (this.checkMissingUnits(existingReservation?.attributes.tour_units)) {
        return
      }

      this.availability = tourDataService.getAllowedDatesFromBuildingInfo(response, responseUnit, this.selectedUnits)
      console.log('Available dates:', this.availability.displayDates)
      this.allowedDates = this.availability.displayDates
      this.$store.commit('saveBuildingInfo', this.info)

      const existingRequest: TourRequest = (this.$store.state as RootState).request as TourRequest

      // Do this after availability is constructed so we can find the right value
      if (existingReservation?.id && existingReservation.attributes.status !== TourStatus.cancelled) {
        console.log('Has an existing reservation')
        this.reservation = existingReservation
        this.personName = existingReservation.attributes.name
        this.email = existingReservation.attributes.email
        this.phoneNumber = existingReservation.attributes.phone_number

        // Careful in this section: The existing reservation date might be in the past and so might not
        // appear in the availability.
        const tempDate: DateTime = DateTime.fromISO(this.availability.getKeyFromDate(DateTime.fromISO(existingReservation.attributes.starts_at)))
        this.currentDateModel = this.availability.displayDates.find((value: VSelectItemType) => {
          return +value.value === +tempDate
        }) as VSelectItemType
        if (this.currentDateModel) {
          // We found the date in the current list, so it wasn't in the past
          this.selectedDate = this.currentDateModel?.value as DateTime
          if (this.selectedDate) {
            this.availability.selectedDate = this.selectedDate
            this.showTimeSlots = true
          }
        } else {
          // The date was not found; probably the date was in the past, so its now filtered or
          // not populated.  Instead, lets set the current date to the earliest available date
          this.useDefaultDate()
        }
      } else if (existingRequest?.name) {
        console.log('Has an existing request')
        this.personName = existingRequest.name
        this.email = existingRequest.email
        this.phoneNumber = existingRequest.phoneNumber
        const tempDate: DateTime = DateTime.fromISO(this.availability.getKeyFromDate(DateTime.fromISO(existingRequest.startsAt)))
        this.currentDateModel = this.availability.displayDates.find((value: VSelectItemType) => {
          return +value.value === +tempDate
        }) as VSelectItemType
        if (this.currentDateModel) {
          // We found the date in the current list, so it wasn't in the past
          this.selectedDate = this.currentDateModel?.value as DateTime
          if (this.selectedDate) {
            this.availability.selectedDate = this.selectedDate
            this.showTimeSlots = true
          }
        } else {
          // The date was not found; probably the date was in the past, so its now filtered or
          // not populated.  Instead, lets set the current date to the earliest available date
          this.useDefaultDate()
        }
      } else {
        console.log('Nothing existing, using default date')
        // No existing reservation
        this.useDefaultDate()
        if (response.attributes.credit_card_hold) {
          this.useCredit = true
          const ccInfo: CreditCardInfo = await stripeService.getKeyAndIntent()
          if (ccInfo?.public_key) {
            this.$nextTick(() => stripeService.stripeElements(ccInfo.public_key, '#card-element'))
          } else {
            console.error('public key not retrievable')
          }
        }
      }

      this.loading = false
    } catch (reason) {
      console.error('Error retrieving api: ', reason)
      this.error = true
      this.loading = false
    }
  }
})
