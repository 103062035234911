


































import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'ErrorDialog',
  props: {
    model: Boolean as PropType<boolean>,
    message: String as PropType<string>,
    callback: Function as PropType<Function>
  }
})
