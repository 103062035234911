






import { DateTime } from 'luxon'
import Vue from 'vue'

export default Vue.extend({
  name: 'CountdownTimer',
  props: ['seconds'],
  data: () => ({
    displayMinutes: '',
    displaySeconds: '',
    endDate: DateTime.invalid('placeholder')
  }),
  mounted () {
    this.endDate = DateTime.local().plus({ seconds: this.seconds })
    this.showRemaining()
  },
  methods: {
    formatNum: (num: number): string => (num < 10 && num >= 0 ? '0' + num.toString() : num.toString()),
    showRemaining (): void {
      const timer = window.setInterval(() => {
        this.updateTime(timer)
      }, 500)

      this.$once('hook:beforeDestroy', () => {
        clearInterval(timer)
      })
    },
    updateTime (timerId: number) {
      const remainingMilliSeconds: number = this.endDate.valueOf() - DateTime.local().valueOf()
      if (remainingMilliSeconds < 0) {
        clearInterval(timerId)
      }
      const millisecondsInMinute = 60 * 1000
      const minutes = Math.max(Math.floor(remainingMilliSeconds / millisecondsInMinute), 0)
      const seconds = Math.max(Math.floor(remainingMilliSeconds % millisecondsInMinute / 1000), 0)
      this.displayMinutes = minutes.toString()
      this.displaySeconds = this.formatNum(seconds)
    }
  }
})
