







import { TourResponse } from '@/types/tour-request-response'
import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'AddToCalendarIcs',
  props: {
    reservation: Object as PropType<TourResponse>
  },
  data: () => ({
    eventText: ''
  }),
  computed: {
    ical (): string {
      return 'data:text/calendar;charset=utf8,' + encodeURI(this.eventText.trim())
    }
  },

  mounted () {
    console.log('mounted with ', this.reservation)
    const language = 'en-us'
    const subject = 'Self-guided tour at ' + this.reservation.attributes.building_name
    const location = this.reservation.attributes.building_address.address_1 + ', ' + this.reservation.attributes.building_address.address_2
    const details = 'Self-guided tour at ' + this.reservation.attributes.building_name
    try {
      this.$ics.removeAllEvents()
      this.$ics.addEvent(language, subject, details, location, this.reservation.attributes.starts_at, this.reservation.attributes.ends_at)
      this.eventText = this.$ics.calendar()
    } catch (e) {
      console.error('Ah, errors.', e)
      this.eventText = e
    }
  }

})
