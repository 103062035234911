




























































import { tourDataService } from '@/services/tour-data-service'
import { ServerError } from '@/types/errors'
import { TermsData } from '@/types/terms-data'
import { AxiosError } from 'axios'
import Vue from 'vue'
import PoweredByButterfly from './PoweredByButterfly.vue'
import ErrorDialog from './ErrorDialog.vue'
import { RootState } from '@/types/root-state'
import { TourRequest } from '@/types/tour-request-response'
import { PersonaResponse } from '@/types/persona'
import Persona from 'persona'
import Client from 'persona/dist/lib/Client'
import { BuildingInfo } from '@/types/building-info'
import { GoogleTagEvents } from '@/services/events'
export default Vue.extend({
  name: 'TermsAndConditions',

  components: {
    PoweredByButterfly,
    ErrorDialog
  },

  data (): TermsData {
    return {
      autostart: false,
      tocCheckbox: false,
      building: null,
      reservation: null,
      error: false,
      loading: false,
      loaded: false,
      saveError: false,
      saveErrorText: '',
      failed: false,
      canceled: false,
      timeSlotUnavailable: false
    }
  },
  computed: {
    useCredit (): boolean {
      return this.building?.attributes.credit_card_hold ?? false
    },
    useId (): boolean {
      return this.building?.attributes.identity_verification ?? false
    }
  },

  methods: {
    async verify () {
      if (!this.building?.attributes.identity_verification) {
        this.accept()
      } else {
        this.failed = false
        this.canceled = false
        this.saveError = false
        this.saveErrorText = ''

        this.verifyPersona()
      }
    },
    async verifyPersona () {
      console.log('Verifying in environment ', process.env.VUE_APP_PERSONA_ENV)
      const client: Client = new Persona.Client({
        templateId: 'tmpl_87nCEujQy5gqA6PnXanXQiVc',
        environment: process.env.VUE_APP_PERSONA_ENV,
        onLoad: (error) => {
          console.log('Terms: ID client loaded')
          if (error) {
            console.error(`Failed with code: ${error.code} and message ${error.message}`)
            this.saveError = true
            this.saveErrorText = error.message
          }

          client.open()
        },
        onComplete: inquiryId => {
          // Inquiry completed. Optionally tell your server about it.
          console.log(`Terms: Complete, Sending finished inquiry ${inquiryId} to backend`)
          this.$gtag.event(GoogleTagEvents.IDSuccess)
          this.accept()
        },
        onEvent: (eventId, metadata) => {
          console.log('Terms: Got event ', eventId)
          console.log('   with metadata ', metadata)
          if (eventId === 'exit') {
            console.log('Terms: User cancelled')
            this.canceled = true
            this.$gtag.event(GoogleTagEvents.IDCanceled)
            this.$router.replace({ name: 'cancelled', params: { id: this.$route.params.handle } })
          } else if (eventId === 'fail') {
            console.log('Terms: User failed to verify')
            this.failed = true
            this.$gtag.event(GoogleTagEvents.IDFailed)
            this.persona = metadata as PersonaResponse
            // We will not get an onComplete, but we can save what we have.
            this.accept()
          } else if (eventId === 'complete') {
            this.persona = metadata as PersonaResponse
          }
        }
      })
    },
    onErrorDismiss (): void {
      this.saveError = false
      if (this.timeSlotUnavailable) {
        this.timeSlotUnavailable = false
        this.$router.replace({ name: 'schedule', params: { handle: this.$route.params.handle } })
      }
    },
    async accept (): Promise<void> {
      this.loading = true
      const request = (this.$store.state as RootState).request as TourRequest
      request.idVerificationBlob = this.persona

      let tourResponse
      try {
        tourResponse = await tourDataService.saveTour(this.building?.attributes?.handle as string, request)
      } catch (e) {
        this.saveError = true
        if (e.isAxiosError) {
          const axiosError: AxiosError = e as AxiosError
          const serverError: ServerError = axiosError.response?.data
          if ((axiosError.response?.status === 400) && (serverError.errors[0]?.detail.includes('is not available') ||
          serverError.errors[0]?.detail.includes('must be at least one hour in the future'))) {
            this.saveErrorText = 'This time is no longer available.  Please select another time'
            this.timeSlotUnavailable = true
          } else if (serverError?.errors?.length > 0) {
            this.saveErrorText = e.message + ': ' + serverError.errors[0].detail
          } else {
            this.saveErrorText = e.message + ' (no detail)'
          }
        } else {
          this.saveErrorText = e.message
        }
      } finally {
        this.loading = false
      }

      if (tourResponse) {
        this.$store.commit('saveReservation', tourResponse)
      }
      if (this.failed) {
        this.$router.replace({ name: 'pending', params: { id: this.$route.params.handle } })
      } else if (this.useId && tourResponse?.attributes.uuid && tourResponse?.attributes.uuid.length > 0 && !this.failed && !this.canceled && this.persona) {
        // SGT-216: If Id verification is enabled, we skip email verification.  Send to the router to confirm it.
        this.$router.replace({ name: 'emailrouter', params: { id: tourResponse?.attributes.uuid ?? '' } })
      } else if (tourResponse) {
        this.$router.replace({ name: 'verify', params: { id: this.$route.params.handle } })
      } else {
        console.error('Error when saving.')
      }
    }
  },

  async mounted () {
    this.building = this.$store.state.buildingInfo as BuildingInfo
    const request = (this.$store.state as RootState).request as TourRequest
    if (!this.building || !request) {
      // No building loaded, may be a page refresh, go back to the schedule page
      this.$router.replace({ name: 'schedule', params: { handle: this.$route.params.handle } })
    }
    console.log('Terms and Conditions, ID verification enabled:', this.building?.attributes.identity_verification ?? 'unset')
    if (this.$route.params.autostart && this.$route.params.autostart === 'true') {
      this.autostart = true
      this.loading = true
      this.verify()
    }
  }
})
